.connection-selector.ant-select.ant-select-single {
  width: 150px;
}

.connection-selector.ant-select.ant-select-single .ant-select-selector {
  height: 42px;
  border-radius: 30px;
  border-color: var(--primary-color);
}

.dark .connection-selector.ant-select.ant-select-single .ant-select-selector {
  background: transparent;
  border-color: #364769;
}

.connection-selector.ant-select.ant-select-single .ant-select-selection-item {
  line-height: 40px;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 14px;
}
.connection-selector.ant-select.ant-select-single .ant-select-arrow {
  color: var(--primary-color);
  height: 29px;
}

.headerWhite .connectionWrapper .connection-selector {
  width: calc(50% - 5px);
}

.dark .connection-selector.ant-select.ant-select-single .ant-select-selection-item {
  color: white;
}

@media (max-width: 1024px) {
  .headerWhite .connectionWrapper .connection-selector:only-child {
    width: 100%;
  }
}
