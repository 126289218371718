.headerWhite {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    border-bottom: 1px solid var(--grey-color);
}

.headerInfo {
    min-width: 150px;
    flex-grow: 1;
}

.logo {
    margin-right: 40px;
    float: left;
}

.logo img {
    width: 110px;
}

.logo .badge {
    margin-left: 15px;
    background: var(--grey-color);
    box-sizing: border-box;
    padding: 2px 10px;
    font-weight: 500;
    border-radius: 60px;
    font-size: 11px;
    color: var(--dark-grey-color);
}

.headerActions {
    min-width: 700px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.headerActions .ant-select-selector:hover {
    background: var(--primary-color);
}
.headerActions .connection-selector:hover .ant-select-selection-item,
.headerActions .connection-selector:active .ant-select-selection-item,
.headerActions .connection-selector:focus .ant-select-selection-item{
    color: #fff;
}
.headerActions .connection-selector:hover .ant-select-arrow svg,
.headerActions .connection-selector:active .ant-select-arrow svg,
.headerActions .connection-selector:focus .ant-select-arrow svg{
    fill: #fff;
}

.connection-selector.ant-select-open .ant-select-selector,
.connection-selector.ant-select-focused .ant-select-selector{
    background: var(--primary-color);
}
.connection-selector.ant-select-open .ant-select-selection-item,
.connection-selector.ant-select-focused .ant-select-selection-item {
    color: #fff !important;
}

.connection-selector.ant-select-open .ant-select-arrow svg,
.connection-selector.ant-select-focused .ant-select-arrow svg {
    fill: #fff !important;
}

@media (max-width: 1024px) {
    .headerActions {
        min-width: 0;
    }
}

.tvlAlIe {
    text-transform: uppercase;
}

.tvlAlIe span {
    color: var(--dark-grey-color);
}

.tvlAlIe strong {
    color: var(--dark-color);
    display: inline-block;
    margin-left: 5px;
}

.accountList {
    display: flex;
    gap: 15px;
    cursor: pointer;
    align-items: center;
}
.accountList:hover {
    cursor: pointer;
}
.accountList:hover .switcher {
    background: #D5D8DB;
}

.account {
    line-height: 18px;
    font-size: 13px;
    font-weight: 500;
}

.account .address {
    display: block;
    color: var(--primary-color);
}

.accountMenu .ant-dropdown-menu-item .accountIcon {
    width: 25px;
}

.accountMenu .ant-dropdown-menu-item img {
    width: 15px;
    object-fit: contain;
}
.accountMenu .ant-dropdown-menu-item:nth-child(1) img {
    width: 13px;
}

.accountMenu .ant-dropdown-menu-item:nth-child(3) img {
    width: 18px;
}

.accountDropdown .ant-dropdown-menu {
    background: var(--light-grey-color);
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    box-shadow: none;
    padding: 10px 10px 10px 5px;
}
.accountDropdown .ant-dropdown-menu-item {
    font-size: 13px;
    font-weight: 500;
    align-items: flex-start;
}
.accountDropdown .ant-dropdown-menu-item:last-of-type .ant-dropdown-menu-title-content {
    line-height: 18px;
}
.accountDropdown .ant-dropdown-menu-item:hover,
.accountDropdown .ant-dropdown-menu-submenu-title:hover {
    background: none;
    text-decoration: underline;
}
.accountDropdown .accountWalletAddress {
    display: block;
    color: var(--dark-grey-color);
}

.dark .ant-select-item-option-content{
    color: #fff;
}
.dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #2c2856;
}
.dark .ant-select-item-option-active {
    background: #2c2856;
}

.connectionWrapper .wallet-adapter-button.connectWallet {
    width: calc(50% - 5px);
}

.wallet-adapter-button.connectWallet {
    background: var(--primary-color) !important;
    color: #fff;
    border-radius: 30px !important;
    font-size: 14px !important;
    line-height: 42px !important;
    height: 42px !important;
    font-weight: 500 !important;
    transition: background .2s ease-in-out;
}
.wallet-adapter-button.connectWallet:hover {
    background: var(--dark-primary-color) !important;
}

.ant-menu-submenu-selected .ant-menu-title-content{
    font-weight: 700;
}
