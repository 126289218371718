.wrapper {
  .addressBlock {
    padding: 20px 0;
    display: flex;
    justify-content: flex-end;
    :global(.ant-space-item):first-child {
      flex-grow: 1;
    }
    :global(.ant-input-affix-wrapper) {
      height: 40px;
    }
    .button {
      width: 110px;
      :global(.ant-spin) {
        display: flex;
        justify-content: center;
      }
    }
  }
}
