@media (max-width: 1024px) {
    * {
        box-sizing: border-box;
    }

    body,
    #root {
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
    }

    .mobile-layout {
        min-height: 100vh;
        padding-bottom: 0;
        background: #f7f9fe !important;
    }

    .mobile-layout .ant-layout-content {
        padding: 0;
    }

    .dark .mobile-layout {
        background: var(--bg-color) !important;
    }

    .mobile-tab {
        padding: 20px 15px 90px 15px;
    }

    .mobile-tabs {
        flex: 1;
    }

    .statistics {
        margin-top: 0;
        padding-top: 0;
        width: 100%;
        gap: 20px;
        flex-direction: column;
        border-bottom: none;
    }

    .statisticItem {
        flex: 1;
        position: relative;
    }

    .statisticBlock-inner {
        justify-content: space-between;
    }

    .statisticBlock-inner .divider {
        /*display: none;*/
        margin-left: 0;
    }

    .statisticBlock-title {
        margin-bottom: 10px;
    }

    .statisticBlock.cashflow .divider {
        display: none;
    }
    .statisticBlock.cashflow .statisticBlock-inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .statisticBlock.cashflow .statisticItem:first-child {
        grid-column: 1 / 3;
    }

    .statisticBlock.cashflow .statisticItem:nth-of-type(3):before,
    .statisticBlock.cashflow .statisticItem:nth-of-type(5):before {
        content: "";
        display: block;
        width: 1px;
        height: 38px;
        left: -20px;
        top: 0;
        position: absolute;
        background: var(--grey-color);
    }

    .statistics .statisticBlock:nth-child(1) {
        order: 2;
    }
    .statistics .statisticBlock:nth-child(2) {
        order: 3;
    }
    .statistics .statisticBlock:nth-child(3) {
        order: 4;
    }
    .statistics .statisticBlock:nth-child(4) {
        order: 1;
    }

    .statisticBlock-inner {
        background: var(--bg-color);
    }

    .statisticBlock.health .ant-progress-outer {
        padding-right: 0;
        margin-right: 10px;
    }

    /* header */
    .headerWhite {
        line-height: 1;
        padding: 10px 20px 10px 20px;
        height: auto;
        flex-direction: column;
    }
    .headerWhite .mobile-inner {
        display: flex;
        align-items: center;
    }
    .headerWhite .connectionWrapper {
        display: flex;
        align-items: center;
        margin-top: 15px;
        justify-content: space-between;
    }
    .headerInfo {
        min-width: auto;
        max-width: 26px;
    }
    .headerInfo .ant-menu {
        display: none;
    }
    .logo {
        float: none;
        margin-right: 0;
    }

    .headerActions {
        min-width: 300px;
    }

    .mobile-tabs .ant-tabs-nav .ant-tabs-ink-bar-animated {
        display: none;
    }

    .mobile-tabs .ant-tabs-nav {
        margin: 0;
        height: 75px;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: var(--bg-color);
        z-index: 100;
    }

    .mobile-tabs .ant-tabs-tab {
        padding: 0;
    }

    .mobile-tabs .ant-tabs-tab-btn {
        font-size: 12px;
        flex: 1;
        text-align: center;
        color: var(--dark-grey-color);
        font-weight: 500;
    }

    .mobile-tabs .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
    }

    .mobile-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--tab-active-color);
    }

    .mobile-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn svg path {
        fill: var(--primary-color);
    }

    .mobile-tabs .mobile-tab-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto 5px auto;
        padding: 7px 0;
    }

    .mobile-tabs .ant-tabs-tab.ant-tabs-tab-active .mobile-tab-icon {
        background: #f0f9ff;
        border-radius: 60px;
    }

    .mobile-tabs.ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
        display: none;
    }

    .ant-tabs-content {
        flex: 1;
    }

    .mobile-token-table {
        /*padding: 15px 15px 75px 15px;*/
    }

    .mobile-token-table .statistics {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 10px;
    }
    .mobile-token-table .statistics .statisticBlock:not(.health) {
        display: none;
    }

    .mobile-market-table .ant-collapse {
        border: none;
        background: none;
    }

    .mobile-market-table .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0;
    }

    .mobile-market-table .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        display: none;
    }

    .mobile-market-table .ant-collapse > .ant-collapse-item {
        border: none;
    }

    .mobile-market-table .ant-collapse-content {
        border-top: none;
    }

    .mobile-market-table .ant-collapse > .ant-collapse-item.ant-collapse-item-active {
        margin-bottom: 10px;
    }

    .mobile-market-table .ant-collapse > .ant-collapse-item.ant-collapse-item-active .market-row svg.arrow {
        transform: rotate(-180deg);
    }

    .mobile-market-table .ant-collapse > .ant-collapse-item .withdraw {
        transform: rotate(-180deg);
    }

    .mobile-market-table .ant-collapse > .ant-collapse-item.ant-collapse-item-active .market-row {
        border-radius: 30px 30px 0 0;
        margin-bottom: 0;
    }

    .mobile-market-table .ant-collapse-content.ant-collapse-content-active {
        border-radius: 0 0 30px 30px;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 25px 25px 25px;
    }

    .tvlAlIe {
        font-size: 12px;
    }

    .market-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--bg-color);
        margin-bottom: 10px;
        padding: 15px 15px 15px 15px;
        border-radius: 60px;
    }

    .market-row .bolder {
        font-size: 16px;
    }

    .market-id {
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
    }

    .market-id img {
        width: 27px !important;
        height: 27px !important;
    }

    .market-id figure {
        position: relative;
    }

    .tablehint {
        font-size: 12px;
        display: block;
        font-weight: 500;
        margin: 0 !important;
    }

    .market-row-inner {
        width: 100%;
        margin: 0 auto;
        border-top: 1px solid var(--grey-color);
        padding-top: 15px;
    }

    .market-row-inner hr {
        height: 1px;
        background: var(--grey-color);
        border: none;
        margin: 15px 0;
    }

    .market-row-inner p {
        display: flex;
        align-items: center;
        flex: 1;
        margin-bottom: 15px;
        gap: 10px;
        line-height: 1;
    }

    .market-row-inner p:last-of-type {
        margin-bottom: 0;
    }

    .market-row-inner .text {
        font-size: 12px;
        font-weight: 500;
        color: var(--dark-grey-color);
        min-width: 90px;
    }

    .market-row-inner .number {
        color: black;
        font-size: 13px;
        max-width: 125px;
        overflow: hidden;
        flex: 1;
    }

    .market-row-inner .action-buttons button {
        border-radius: 60px;
    }

    .market-row-inner .action-buttons button.empty {
        margin: 25px 0 10px 0;
        background: transparent;
        color: #2260e5;
        border: 1px solid #2260e5;
    }

    .mobile-token-table h2 {
        margin-top: 30px;
        margin-bottom: 25px;
    }

    .mobile-token-table .userHasAssets {
        background: var(--primary-color);
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 100%;
        border: 2px solid white;
        box-sizing: content-box;
        transform: translate(25%, 25%);
    }

    .ant-modal.supply-or-borrow-modal {
        max-width: 100%;
        padding-bottom: 0;
        margin-bottom: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        top: auto;
    }
    .ant-modal.supply-or-borrow-modal .ant-modal-content {
        border-radius: 30px 30px 0 0;
    }
    .ant-modal.tooltip-modal .ant-modal-body {
        padding: 20px !important;
        text-align: center;
    }
    .ant-modal.tooltip-modal .ant-btn {
        border: none;
        font-weight: 500;
        box-shadow: none;
        margin-top: 25px;
    }
    .modal-tabs .ant-tabs-content-holder {
        background: white;
    }

    .mobile-account ul {
        padding: 0;
    }
    .mobile-account ul li {
        display: flex;
        align-items: center;
        padding: 20px 15px;
        gap: 20px;
        line-height: 1;
        position: relative;
        background: white;
    }
    .mobile-account ul li button {
        display: flex;
        align-items: center;
        gap: 20px;
        line-height: 1;
        width: 100%;
        text-align: left;
    }
    .mobile-account ul li:after {
        background: var(--grey-color);
        height: 1px;
        width: 90%;
        margin: 0 auto;
        content: "";
        position: absolute;
        bottom: 0;
        left: 5%;
    }
    .mobile-account ul li:first-of-type {
        border-radius: 7px 7px 0 0;
    }
    .mobile-account ul li:last-of-type {
        border-radius: 0 0 7px 7px;
    }
    .mobile-account ul li:only-child {
        border-radius: 7px;
    }
    .mobile-account ul li:last-of-type:after {
        display: none;
    }
    .mobile-account .icon {
        width: 32px;
        height: 32px;
        background: #edf0f4;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mobile-account .icon svg path {
        fill: var(--primary-color);
    }
    .mobile-account .text {
        flex: 1;
        font-weight: 500;
        font-size: 14px;
    }
    .mobile-account .text .small {
        font-size: 12px;
        display: block;
        margin-bottom: 5px;
    }
    .mobile-account .text .wallet {
        color: var(--primary-color);
        font-size: 20px;
        font-weight: 500;
    }
    .mobile-account .copy {
        position: absolute;
        top: 18px;
        right: 18px;
    }

    .history-date {
        display: block;
        padding: 15px 0 10px 0;
        color: black;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid var(--grey-color);
    }
    .history-date .tablehint {
        display: block;
        margin-bottom: 5px;
    }
    .history-link {
        width: 100%;
        height: 50px;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        font-weight: 700;
        font-size: 14px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
    }
    .history-link img {
        margin-right: 5px;
    }

    /* dark */
    .dark .market-row {
        background: #201d3d;
    }
    .dark .market-row svg path {
        fill: white;
    }
    .dark .mobile-token-table h2 {
        color: white;
    }
    .dark .ant-collapse-content {
        background: #201d3d;
    }
    .dark .market-row-inner {
        border-top-color: #191633;
    }
    .dark .market-row-inner hr {
        background: #191633;
    }
    .dark .market-row-inner .number {
        color: white;
    }
    .dark .market-row-inner .action-buttons button.empty {
        color: white;
        border-color: white;
    }
    .dark .ant-modal-body {
        color: #fff;
        border: none;
    }
    .dark .ant-modal .ant-modal-content {
        background: #201d3d;
    }
    .dark .close-btn {
        background: none;
        color: white;
    }
    .dark .modal-tabs .ant-tabs-nav-list {
        background: #191633;
    }
    .dark .ant-tabs-nav-list {
        border-radius: 30px 30px 0 0;
    }
    .dark .mobile-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: white;
    }
    .dark .mobile-tabs .ant-tabs-tab.ant-tabs-tab-active .mobile-tab-icon {
        background: #182641;
    }
    .dark .logo svg path {
        fill: white;
    }
    .dark .market-row {
        color: white;
    }
    .dark .market-row svg path:nth-of-type(2) {
        fill: transparent;
    }
    .dark .mobile-account li {
        background: #201d3d;
        color: white;
    }
    .dark .mobile-account ul li:after {
        background: #191633;
    }
    .dark .mobile-account .icon {
        background: #191633;
    }
    .dark .mobile-account .copy svg path {
        fill: white;
    }
    .dark .headerWhite {
        border: none;
    }
    .dark .history-date {
        border-bottom-color: #191633;
    }
    .dark .history-link {
        color: white;
        border-color: white;
    }
    .dark .mobile-token-table .userHasAssets {
        border-color: #201d3d;
    }
    .dark .mobile-token-table h2 .ant-btn-primary {
        background: var(--primary-color);
    }

    .wallet-adapter-modal-container {
        align-items: flex-end;
        margin: 0;
        min-height: 100vh;
    }
    .wallet-adapter-modal-wrapper {
        border-radius: 30px 30px 0 0;
        box-shadow: none;
        background: white;
    }
    .wallet-adapter-modal-title {
        display: none;
    }
    .wallet-adapter-modal-wrapper .wallet-adapter-button {
        flex-direction: row-reverse;
        justify-content: flex-end !important;
        gap: 15px;
        background: white;
        color: black;
        font-weight: 500;
        padding: 0;
    }
    .ant-input-number-lg input {
        font-size: 22px;
    }

    .staking-block {
        padding: 25px 15px;
        margin-bottom: 0;
        border-radius: 0;
    }
    .ant-tabs.staking-tabs {
        min-width: 100%;
    }
    .staking-block .container, .staking-statistics .container {
        width: 100%;
    }
    .staking-flex {
        min-height: auto;
        flex-direction: column;
    }
    .staking-form, .staking-earnings {
        width: 100%;
    }
    .staking-earnings {
        margin-top: 45px;
    }
    .staking-statistics {
        padding: 25px 15px;
    }
    .staking-conditions {
        font-size: 14px;
        line-height: 16px;
    }
    .staking-stat {
        flex-direction: column;
        gap: 15px;
    }
    .staking-stat-title {
        height: auto;
    }
    .staking-stat-title br {
        display: none;
    }

    .ant-drawer-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .ant-drawer-content-wrapper {
        width: 100% !important;
    }
    .stake-header-details-status {
        margin-left: 10px;
    }

    .mobile-tabs .ant-tabs.staking-tabs .ant-tabs-nav {
        position: static;
        background: transparent;
        height: auto;
        margin-bottom: 15px;
    }
    .mobile-tabs .ant-tabs.staking-tabs .ant-tabs-nav-list .ant-tabs-tab {
        margin: 0 10px;
    }
    .mobile-tabs .ant-tabs.staking-tabs .ant-tabs-nav-list .ant-tabs-tab:nth-of-type(1) {
        margin-left: 0;
    }
    .staking-block, .staking-statistics {
        padding: 0;
        background: none;
    }
    .staking-statistics {
        margin-top: 55px;
        margin-bottom: 55px;
    }
    .staking-stat {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .staking-stat-number {
        font-size: 18px;
    }
    .staking-alert {
        width: 100%;
    }
    .alert-modal {
        width: 100% !important;
    }
    .alert-modal .ant-form-item-required {
        display: block;
    }
    .alert-modal .alert-modal-type {
        flex-direction: row !important;
    }
    .alert-modal .alert-modal-type .ant-form-item-label{
        flex: 1;
    }
    .alert-modal .alert-modal-type .ant-form-item-control {
        flex: 0;
    }
}
@media (max-width: 1300px) {
    .statistics .statisticBlock {
    }
    .statistics .statisticBlock.health{
        flex: 1 1 100%;
    }
    .statistics .statisticBlock.health .statisticBlock-inner img {
        margin-right: 5px;
    }
    .statistics .statisticBlock.health .statisticBlock-inner strong{
        display: none;
    }
}
