.statistics {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0 10px 0;
    border-bottom: 1px solid var(--grey-color);
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.statistics.blurred {
    filter: blur(3px);
}

/* cashflow */
.statisticBlock.cashflow .statisticItem:first-child {
    min-width: 60px;
}

.statisticBlock.cashflow .statisticItem {
    /*min-width: 100px;*/
}
.statisticBlock.cashflow .statisticBlock-inner {
    gap: 25px;
}
.statisticBlock.cashflow .statisticBlock-inner .divider {
    margin: 0;
}

/* health */
.statisticBlock.health strong{
    display: block;
    white-space: nowrap;
    min-width: 110px;
    margin: 0 5px 0 5px;
}
.statisticBlock.health .ant-progress {
    display: flex;
}
.statisticBlock.health .ant-progress-outer {
    margin-right: 0;
    padding-right: 20px;
}
.statisticBlock.health .ant-progress-text {
    font-weight: 600;
    font-size: 20px;
    margin-left: 0;
    width: auto;
}
.statisticBlock.health .statisticBlock-inner {
    display: block;
}
.statisticBlock.health .statisticBlock-title span {
    color: var(--green-color);
    display: inline;
    margin-left: 10px;
}
.statisticBlock.health .ant-progress-outer {
    position: relative;
}
.statisticBlock.health .ant-progress-outer:after{
    content: '';
    display: block;
    background: url('../../Health/Health/divider.svg') center center no-repeat;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 90%;
    top: 0;
}

.statisticBlock-title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
}

.statisticBlock-inner {
    background: var(--light-grey-color);
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    min-height: 74px;
}

.divider {
    height: 38px;
    width: 1px;
    display: block;
    background: var(--grey-color);
    margin: 0 20px;
}
