.staking-block {
    background: #F4F4F4;
    padding: 50px 0;
    border-radius: 15px;
    margin-bottom: 25px;
    margin-top: 10px;
}

.staking-block .ant-tabs-content-holder {
    min-height: 260px;
}

.staking-block h2 {
    margin-bottom: 25px;
}

.staking-block .container, .staking-statistics .container {
    width: 1000px;
    margin: 0 auto;
}

.staking-statistics {
    padding: 25px 0;
}

.staking-statistics h2 {
    margin-bottom: 30px;
}

.staking-stat-title {
    display: block;
    line-height: 17px;
    height: 44px;
}

.staking-stat-number {
    font-size: 21px;
    font-weight: 600;
    display: block;
}

.staking-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 260px;
}

.staking-stat {
    display: flex;
    gap: 75px;
}

.ant-tabs.staking-tabs {
    margin-top: 0;
    overflow: visible;
    min-width: 400px;
}
.ant-tabs.staking-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:after {
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--primary-color);
    /*content: '';*/
}
.ant-tabs.staking-tabs .ant-tabs-tab.ant-tabs-tab-active .unstake-finished-count{
    background: var(--primary-color);
}

.ant-tabs.staking-tabs .ant-tabs-tab-btn{
    font-size: 16px;
}

.ant-tabs.staking-tabs .ant-tabs-nav {
    margin-bottom: 20px !important;
}

.staking-form .ant-form-item{
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}
.staking-form {
    width: 400px;
}
.staking-form .wallet-adapter-button.connectWallet {
    width: 100%;
    height: 60px !important;
    align-items: center;
    justify-content: center !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
.staking-form .ant-form-item-label {
    padding: 0;
}
.staking-form .input-holder{
    background: white;
    padding: 10px 15px;
    margin-bottom: 1px;
    border-radius: 5px;
    position: relative;
}
.staking-form .input-holder label {
    font-size: 13px;
}
.staking-form .input-holder.last-item {
    margin-top: -17px;
    background: none;
    padding: 0;
    margin-bottom: 50px;
}
.staking-form .ant-input-number-lg input {
    height: 30px;
    padding: 0;
    font-size: 21px;
}
.staking-form .inputPopup {
    border: none;
    outline: none;
    box-shadow: none;
}
.staking-form .ant-slider-with-marks {
    margin: 10px 0 28px 0;
}
.staking-form .ant-slider-rail {
    background: #C4C7C5;
    height: 3px;
    border-radius: 0 0 10px 10px;
}
.staking-form .ant-slider-dot {
    display: none;
}
.staking-form .ant-slider-track, .staking-form .ant-slider-step {
    height: 3px;
}
.staking-form .ant-slider-track {
    background: var(--primary-color);
}
.staking-form .ant-slider-handle {
    background: var(--primary-color);
    border-color: var(--primary-color);
}
.staking-form .ant-slider:hover .ant-slider-track {
    background: var(--primary-color);
}
.staking-form .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: var(--primary-color);
}
.staking-form .ant-slider-mark-text {
    font-size: 11px;
    width: 50px;
    color: #8F8F8F;
}
.staking-form .ant-slider-mark-text:nth-of-type(1) {
    left: 2.5% !important;
}
.staking-form .staking-button {
    height: 60px;
    font-weight: bold;
}

.staking-earnings {
    width: 330px;
}
.staking-monthly-earnings {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    border-bottom: 1px solid black;
    padding-bottom: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    min-width: 330px;
}
.staking-earnings .staking-montlhy-month {
    line-height: 18px;
}
.staking-monthly-earnings .staking-amount {
    font-size: 20px;
    font-weight: bold;
    min-width: 150px;
    text-align: right;
    transform: translateY(5px);
}
.staking-earnings .staking-apy {
    display: flex;
    justify-content: space-between;
}
.staking-earnings .staking-apy strong {
    display: flex;
}
.staking-earnings .staking-apy svg {
    margin-left: 5px;
    width: 18px;
    height: 18px;
    transform: translateY(2px);
}
.staking-deposit-all {
    background: #EDF0F4;
    display: block;
    padding: 5px 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.staking-deposit-all {
    color: rgba(34, 96, 229, .8);
    font-size: 12px;
    line-height: 18px;
}
.staking-deposit-all strong {
    color: rgba(34, 96, 229, 1);
    font-size: 14px;
    letter-spacing: -1px;
    font-weight: 600;
}
.staking-tabs .ant-slider-mark {
    left: 10px;
    width: calc(100% - 30px);
}
.staking-helpers {
    display: flex;
    justify-content: space-between;
    font-size: 11px;
    color: #8F8F8F;
}
.staking-helpers span {
    cursor: pointer;
}

.tooltip-table {
    max-width: 380px;
    width: 100%;
}
.tooltip-table-content {
    width: 100%;
}
.tooltip-table .ant-tooltip-arrow{
    display: block;
}
.tooltip-table .ant-tooltip-inner {
    border-radius: 10px;
    margin-top: 0;
}
.tooltip-table .ant-tooltip-arrow-content {
    background: #fff;
}

/* unstake */
.unstake-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 10px;
}
.unstake-card {
    background: #fff;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 10px;
    transition: box-shadow .5s ease-in-out;
    border: 2px solid transparent;
    cursor: pointer;
}
.unstake-card:hover {
    /*box-shadow: 0 2px 10px rgba(0,0,0,.2);*/
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}
.unstake-card-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.unstake-oxy-count {
    color: rgba(196, 199, 197, 1);
    font-weight: 500;
    font-size: 21px;
    max-width: 80%;
    /*min-width: 80%;*/
    flex: 1;
}
.unstake-oxy-count strong {
    color: rgba(50, 50, 50, 1);
    margin-right: 5px;
}
.unstake-apy {
    font-size: 10px;
    line-height: 14px;
    color: rgba(143, 143, 143, 1);
    text-align: center;
}
.unstake-apy span {
    color: rgba(50, 50, 50, 1);
    font-size: 12px;
}

.ustake-card-bottom {
    margin-top: 20px;
}
.unstake-card.finished .ustake-card-bottom{
    margin-top: 10px;
}
.unstake-progress {
    display: flex;
    align-items: center;
    flex: 1;
}
.unstake-progress-dates {
    color: rgba(143, 143, 143, 1);
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
}
.unstake-progress-dates strong {
    color: rgba(50, 50, 50, 1);
    font-size: 12px;
    display: block;
}
.ustake-card-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.unstake-modal-btn {
    background: rgba(34, 96, 229, 0.08);
    color: var(--primary-color);
    font-size: 12px;
    border: none;
    font-weight: 500;
    border-radius: 8px;
}
.unstake-modal-btn:hover, .unstake-modal-btn:focus {
    background: rgba(34, 96, 229, 0.2);
    color: var(--primary-color);
}

.unstake-finished-tab {
    display: flex;
    align-items: center;
}
.unstake-finished-count {
    background: rgba(143, 143, 143, 1);
    color: #fff;
    font-size: 9px;
    border-radius: 10px;
    margin-left: 5px;
    font-weight: bold;
    padding: 0 8px;
    letter-spacing: -0.5px;
}
.unstake-title {
    margin-top: 35px;
    display: block;
    color: rgba(196, 199, 197, 1);
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
}
.unstake-card-buttons {
    text-align: right;
    flex: 1;
}
.unstake-card-buttons button{
    margin-bottom: 4px;
}
.unstake-card-buttons button:last-of-type {
    margin-bottom: 0;
}
.unstake-card.early .unstake-oxy-count {
    text-decoration: line-through;
}
.unstake-card.early .unstake-oxy-count strong, .unstake-card.finished .unstake-oxy-count strong{
    color: rgba(196, 199, 197, 1);
}
.unstake-card.early .unstake-progress {
    text-decoration: line-through;
}
.unstake-card.early .unstake-card-top{
    color: rgba(196, 199, 197, 1) !important;
    text-decoration: line-through;
}
.unstake-card.early .unstake-apy span, .unstake-card.early .unstake-apy, .unstake-card.finished .unstake-apy span, .unstake-card.finished .unstake-apy {
    color: rgba(196, 199, 197, 1) !important;
}
.unstake-card.early .unstake-progress {
    color: rgba(196, 199, 197, 1) !important;
}
.unstake-card.early .unstake-progress-dates span, .unstake-card.early .unstake-progress-dates strong {
    color: rgba(196, 199, 197, 1) !important;
}
.stake-pool-progress .ant-progress-status-normal .ant-progress-text{
    display: none;
}
.ant-progress-status-exception .ant-progress-text .anticon-close svg{
    fill: rgba(196, 199, 197, 1);
}
.unstake-tokens-available-date {
    display: block;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
}

/* staking pool details */
.stake-pool-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.stake-header-details-left {
    display: flex;
    align-items: center;
    gap: 5px;
    color: rgba(50, 50, 50, 1);
    font-size: 21px;
}
.stake-header-token {
    color: rgba(196, 199, 197, 1);
}
.stake-header-token {
    margin-left: 5px;
}
.stake-pool-details-header strong {
    font-weight: 800;
}
.stake-header-details-status {
    background: rgba(237, 240, 244, 1);
    font-size: 12px;
    color: black;
    padding: 5px 10px;
    border-radius: 60px;
}

.stake-pool-item:not(:last-of-type){
    margin-bottom: 30px;
}
.stake-pool-title {
    font-weight: 600;
    font-size: 22px;
    display: block;
    line-height: 22px;
}
.stake-pool-text {
    color: var(--dark-grey-color);
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
}
.stake-pool-text svg {
    width: 16px;
    height: 16px;
}
.stake-pool-progress .stake-pool-icon {
    min-width: 16px;
    min-height: 16px;
    margin-left: 5px;
}
.stake-pool-sub {
    display: block;
    flex: 1;
    width: 100%;
    color: var(--dark-grey-color);
}
.stake-pool-sub span {
    color: black;
}
.blue {
    color: var(--primary-color);
}
.stake-pool-progress {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.stake-pool-progress .ant-progress-outer{
    padding-right: 0;
    margin-right: 0;
}
.stake-pool-progress span {
    margin-left: 10px;
    font-size: 12px;
    color: var(--dark-grey-color);
}
.stake-pool-progress .ant-progress-text{
    display: none;
}
.stake-pool-item.stake-pool-button {
    background: #F5F5F5;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding: 20px 15px;
    border-radius: 5px;
}
.stake-pool-item.stake-pool-button .stake-pool-text {
    color: rgba(109, 109, 109, 1);
    display: block;
    margin-bottom: 15px;
    margin-top: 0;
    font-size: 13px;
    line-height: 16px;
}
.stake-pool-item.stake-pool-button .ant-btn-round.ant-btn-lg {
    font-size: 14px;
    font-weight: 500;
}

.stake-pool-item .ant-btn.ant-btn-ghost{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-weight: 500;
}

.unstake-card.active {
    border-color: var(--primary-color);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}

.drawer-flex {
    display: flex;
    align-items: center;
}


.dark .staking-statistics h2, .dark .staking-block h2 {
    color: white;
}

.dark .staking-block {
    background: none;
}
.dark .staking-form .input-holder {
    background: #201D3D;
}
.dark .staking-form .input-holder label{
    color: white;
}
.dark .staking-form .inputPopup {
    border: none !important;
    background: none;
}
.dark .staking-deposit-all {
    background: var(--bg-color);
    color: white;
}
.dark .staking-deposit-all strong {
    color: white;
}
.dark .staking-monthly-earnings {
    border-bottom-color: #201D3D;
}
.dark .unstake-card {
    background: #201D3D;
}
.dark .unstake-oxy-count strong, .dark .unstake-apy span, .dark .unstake-progress-dates strong, .dark .stake-header-details-left, .dark .stake-pool-item.stake-pool-button .stake-pool-text, .dark .stake-pool-sub span {
    color: white;
}
.dark .ant-drawer-content, .ant-drawer-header, .dark .stake-pool-item.stake-pool-button, .dark .stake-header-details-status {
    background: var(--bg-color);
}
.dark .stake-pool-item.stake-pool-button, .dark .ant-drawer-header {
    border-color: #201D3D;
}
.dark .stake-header-details-status {
    background: #201D3D;
    color: white;
}
.dark .ant-message .anticon svg{
    fill: var(--bg-color);
}
.dark .stake-header-details-left svg circle:nth-of-type(1){
    fill: #201D3D;
}

.stake-pool-content.finished-drawer .stake-pool-item .ant-typography,
.stake-pool-content.finished-drawer .stake-pool-sub span,
.stake-pool-content.finished-drawer .stake-pool-title .blue{
    color: var(--dark-grey-color);
}
.stake-pool-content.finished-drawer .stake-pool-progress .ant-progress-bg {
    background: var(--dark-grey-color) !important;
}
.stake-pool-content.finished-drawer .stake-pool-active-only {
    border-top: 1px solid rgba(240, 240, 240, 1);
    padding-top: 30px;
}
.stake-pool-content.finished-drawer .stake-pool-active-only .stake-pool-title{
    color: black !important;
}
.dark .stake-pool-content.finished-drawer .stake-pool-active-only .stake-pool-title {
    color: white !important;
}

.ant-modal-wrap {
    z-index: 1050;
}
.stake-modal-instant .ant-modal-body{
    padding: 25px 25px 20px 25px !important;
}
.stake-modal-instant .ant-modal-footer {
    display: flex;
    align-items: center;
    border-top: none;
    padding-top: 0;
    padding-bottom: 25px;
}
.stake-modal-instant .ant-modal-footer button {
    width: 50%;
    font-size: 14px;
    font-weight: 500;
}
.stake-modal-instant .ant-modal-footer .ant-btn.ant-btn-lg {
    color: var(--primary-color);
    border-color: var(--primary-color);
}
.ant-modal-content {
    border-radius: 10px;
}

.staking-drawer-close .ant-btn{
    font-size: 14px;
    font-weight: 500;
    border-color: #CECECE;
    border-radius: 10px;
    color: var(--dark-grey-color);
}
.staking-drawer-close .ant-btn:hover, .staking-drawer-close .ant-btn:focus, .staking-drawer-close .ant-btn:active {
    color: black;
}
.dark .staking-drawer-close .ant-btn {
    background: transparent;
    color: white;
    border-color: #201D3D;
}

.staking-conditions {
    color: var(--dark-grey-color);
    font-size: 12px;
    display: block;
    margin-top: 12px;
    text-align: center;
    line-height: 15px;
}
.staking-conditions strong {
    font-weight: 500;
}
