:root {
  --bg-color: #fff;
  --primary-color: #2260E5;
  --antd-wave-shadow-color: #2260E5;
  --grey-color: rgb(236, 236, 236);
  --dark-grey-color: #8F8F8F;
  --light-grey-color: #FBFBFB;
  --dark-color: #323232;
  --red-color: #D73737;
  --green-color: #01D78A;
  --success-color: #6BD492;
  --menu-color: var(--primary-color);
  --tab-active-color: #637599;
  --dark-primary-color: #1443AA;
}

a, a:hover, a:focus, a:active {
  color: var(--primary-color);
}

.ant-message {
  z-index: 1065;
}

.dark {
  --bg-color: #191633;
  --menu-color: #fff;
  --dark-color: #fff;
  background: var(--bg-color);
}

.dark .account, .dark .account .address {
  color: #fff;
}
.dark .logo .badge {
  color: var(--primary-color);
  background: #fff;
}
.dark .statisticBlock-inner {
  background: #201D3D;
}
.dark .statisticItem {
  color: var(--dark-grey-color);
}
.dark .statisticBlock-title {
  color: #fff;
}
.dark .divider {
  background: #191633;
}
.dark .statisticBlock.health strong {
  color: #fff;
}
.dark .info {
  color: var(--dark-grey-color);
}
.dark .general-tables .ant-table-tbody .ant-table-row:hover td {
  background: #2c2856 !important;
}
.dark .tabs-market .ant-table-tbody tr .ant-table-cell {
  border: none;
}
.dark .table .even {
  background: #201D3D !important;
}
.dark .ant-table-thead tr .ant-table-cell {
  color: #8F8F8F;
}
.dark .ant-typography {
  color: #fff;
}
.dark .ant-tabs.tabs-market .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff;
}
.dark .ant-tabs.tabs-market .ant-tabs-tab-btn {
  color: var(--dark-grey-color);
}
.dark .headerWhite {
  border-bottom-color: #364769;
}
.dark .statistics {
  border-bottom: none;
}
.dark .general-tables .tablehint {
  color: #8F8F8F;
}
.dark .ant-progress-inner {
  background: #273550;
}
.dark .general-tables td.ant-table-cell-fix-right-first:before {
  background: #364769;
}
.dark .ant-btn-round.ant-btn-sm {
  background: #273550;
  border: none;
  color: #fff;
}
.dark .ant-table-thead .ant-btn-round.ant-btn-primary {
  background: var(--primary-color);
}
.dark .ant-spin-nested-loading {
  background: #191633 !important;
}
.dark .accountDropdown .ant-dropdown-menu{
  background: #201D3D !important;
  border: none;
}
.dark .ant-select-dropdown {
  background: #201D3D !important;
}
.dark .accountDropdown .ant-dropdown-menu-item {
  color: #fff;
}
.dark .switcher {
  background: #273550;
}
.dark .modal-tabs .ant-tabs-content-holder {
  background: #201D3D;
}
.dark .modal-tabs .ant-tabs-nav-list {
  background: #0F0C28;
}
.dark .ant-modal-body {
  border: 1px solid #0F0C28;
}
.dark .modal-subinfo {
  background: #191633;
  border: 1px solid #0F0C28;
}
.dark .inputPopup {
  background: #191633;
  border: 1px solid #0F0C28 !important;
}
.dark .ant-input-number-lg input {
  color: #fff;
}
.dark .modal-tabs .ant-tabs-nav .ant-tabs-tab:first-of-type .ant-tabs-tab-btn {
  border-right-color: #364769;
}
.dark .modal-tabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.dark .ant-tabs-tab-btn {
  color: #637599;
}
.dark .roundButtonInput {
  background: #201D3D;
}
.dark .ant-tooltip-inner {
  background: #201D3D;
  border: 1px solid #0F0C28;
}
.dark .ant-pagination-item {
  background: #201D3D;
}
.dark .ant-pagination-item a{
  color: #fff;
}
.dark .ant-pagination-prev .ant-pagination-item-link, .dark .ant-pagination-next .ant-pagination-item-link {
  background: #201D3D;
}
.dark .anticon svg {
  fill: #fff;
}

.red {
  color: var(--red-color);
}

.green {
  color: var(--green-color);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  display: flex;
  justify-content: flex-start;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--primary-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* layout */
.ant-layout-content {
  padding: 0 25px;
}
.mb0 {
  margin-bottom: 0 !important;
}

/* header */
.headerWhite {
  background: var(--bg-color);
}

/* menu */
.ant-menu {
  background: var(--bg-color);
  transition: none;
}
.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-item span{
  color: var(--menu-color);
}
.ant-menu-item:after {
  display: none;
}
.ant-menu-item:hover span{
  color: var(--primary-color) !important;
  text-decoration: underline;
}
.ant-menu-item.ant-menu-item-selected span {
  color: var(--menu-color);
  font-weight: 700;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: var(--primary-color) !important;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: var(--menu-color);
}

/* button */
.ant-btn-primary {
  background: var(--primary-color);
  font-weight: 500;
  height: 40px;
  box-shadow: none;
  padding: 4px 20px;
  border: none;
}
.ant-btn-primary:active, .ant-btn-primary:focus, .ant-btn-primary:hover {
  background: var(--dark-primary-color);
}
.ant-btn-primary[disabled] {
  background: var(--primary-color);
  opacity: .3;
  color: #fff;
}
.ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:hover {
  background: var(--primary-color);
  opacity: .3;
  color: #fff;
}

.ant-btn-default {
  font-weight: 500;
  height: 40px;
  box-shadow: none;
  padding: 4px 20px;
  color: var(--primary-color);
  border: 1px solid var(--grey-color);
}

/* inputs */
input {
  line-height: normal;
}
.ant-input-number-lg input {
  height: 52px;
  font-size: 28px;
  font-weight: 600;
}
.ant-input-number-lg input::placeholder {
  /*font-size: 14px;*/
}


/* tabs */
.ant-tabs.tabs-market {
  margin-top: 22px;
}
.ant-tabs.tabs-market .ant-tabs-nav {
  margin-bottom: 10px;
}
.ant-tabs.tabs-market .ant-tabs-tab {
  width: auto;
  padding: 0;
}
.ant-tabs.tabs-market .ant-tabs-tab-btn {
  font-size: 20px;
  font-weight: 500;
}
.ant-tabs.tabs-market .ant-tabs-tab-btn:hover {
  color: var(--primary-color);
}
.dark .ant-tabs.tabs-market .ant-tabs-tab-btn:hover {
  color: #fff;
}
.dark .ant-checkbox-wrapper {
  color: #fff;
}
.dark .roundTokenAsset{
  color: #fff;
  border: 1px solid #fff;
}
.ant-tabs.tabs-market .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color);
}
.ant-tabs.tabs-market .ant-tabs-nav:before{
  display: none;
}
.ant-tabs.tabs-market .ant-tabs-tab.ant-tabs-tab-active {
  background: none;
}
.ant-tabs.tabs-market .ant-tabs-ink-bar-animated {
  display: none;
}

/* tables */
.ant-table-cell-fix-left, .ant-table-cell-fix-right {
  /*background: #fff !important;*/
}
.ant-table-cell:before {
  display: none;
}

span.bolder {
  font-weight: 500;
}


/* tooltips */
.ant-tooltip {
  border-radius: 6px;
}
.ant-tooltip .ant-tooltip-arrow {
  display: none;
}
.ant-tooltip-inner {
  padding: 15px;
  box-sizing: border-box;
  margin-top: -10px;
  background: #FBFBFB;
  color: var(--dark-color);
  font-size: 12px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
}

.general-tabs.ant-tabs .ant-tabs-nav {
  margin-bottom: 15px;
}

.general-tables {
  background: none !important;
}

.general-tables .ant-table-thead tr:first-child th {
  color: var(--dark-color);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  text-align: left !important;
  padding-left: 0;
}

.general-tables .ant-table-cell:not:first-child {
  padding-left: 0;
}

.table .even {
  background: var(--light-grey-color) !important;
}
.table .even .ant-table-cell.ant-table-cell-fix-right {
  background: var(--light-grey-color) !important;
}
.table .ant-table-cell.ant-table-cell-fix-right {
  background: #fff !important;
  transition: none;
}
.dark .table .ant-table-cell.ant-table-cell-fix-right {
  background: var(--bg-color) !important;
}
.dark .table .even .ant-table-cell.ant-table-cell-fix-right {
  background: #201D3D !important;
}
.dark .table .odd {
  background: var(--bg-color);
}
.dark .tabs-market table {
  background: var(--bg-color);
}
.dark .statisticBlock.health .ant-progress-text {
  color: #fff;
}

.general-tables .ant-table-cell {
  font-size: 16px;
  font-weight: 500;
  transition: none;
}

.general-tables th.ant-table-cell {
  font-size: 14px;
}

.general-tables td.ant-table-cell-fix-right-first:before {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: #F0F0F0;
  position: absolute;
  left: 0;
  top: 0;
}
.general-tables .ant-table-tbody .ant-table-row:hover td{
  background: var(--grey-color) !important;
  cursor: pointer;
}
.tablehint {
  font-size: 14px;
  color: var(--dark-grey-color);
}
.general-tables .tablehint span{
  margin-right: 5px;
}

@media (max-width: 1367px) {
  .tablehint {
    font-size: 12px;
  }
  .general-tables .ant-table-cell {
    font-size: 14px;
  }
  .statisticBlock-title {
    font-size: 14px;
  }
  .statisticItem-value {
    font-size: 14px;
  }
  .statisticBlock-inner, .statisticBlock.cashflow .statisticBlock-inner {
    gap: 10px;
  }
  .statisticItem {
    font-size: 11px;
  }
  .divider {
    margin: 0 5px;
  }
  .statisticBlock.health .ant-progress-outer:after {
    left: 80%;
  }
}

.modal-tabs .ant-tabs-ink-bar {
  display: none;
}
.modal-tabs .ant-tabs-nav {
  margin: 0;
}
.modal-tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 15px 0;
  margin: 0;
}
.modal-tabs .ant-tabs-nav .ant-tabs-tab:first-of-type .ant-tabs-tab-btn {
  border-right: 1px solid var(--grey-color);
}
.modal-tabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn{
  color: var(--primary-color);
}
.modal-tabs .ant-tabs-nav .ant-tabs-tab-btn {
  font-size: 16px;
  font-weight: 500;
}
.modal-tabs .ant-tabs-nav .ant-tabs-tab-btn:hover {
  color: var(--primary-color);
}
.modal-tabs .ant-tabs-content-holder {
  background: #F7F9FD;
}

.modal-subinfo {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--grey-color);
  font-size: 12px;
}

.relative {
  position: relative;
}

.relative .ant-space-item:nth-of-type(2) {
  flex: 1;
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 25px;
}

.dark .no-data {
  color: white;
}
.dark .no-data svg path {
  fill: white;
}

.ant-input-number-input::-webkit-inner-spin-button,
.ant-input-number-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-input-number-handler-wrap {
  display: none;
}

.spinner-container {
  text-align: center;
  padding-top: 25px;
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after, .ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: none;
}

.ant-menu-title-content a, .ant-menu-title-content a:hover, .ant-menu-title-content a:focus {
  color: var(--primary-color) !important;
}
.dark .ant-menu-title-content a, .dark .ant-menu-title-content a:hover, .dark .ant-menu-title-content a:focus {
  color: #fff !important;
}
.dark .ant-menu-submenu-active .ant-menu-title-content, .dark .ant-menu-submenu-selected .ant-menu-title-content {
  color: #fff !important;
}
.dark .ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child:hover{
  color: #fff !important;
}
.dark .ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child:hover span {
  color: #fff !important;
}
.dark .ant-menu-submenu-popup > .ant-menu {
  background: #201D3D;
}
.dark .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #2c2856;
}
.dark .ant-menu-item-group-list .ant-menu-item:hover, .dark .ant-menu-item-group-list .ant-menu-item:hover span {
  background: #2c2856 !important;
  color: #fff !important;
}

.ant-menu-item-group-title {
  display: none;
}
.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after {
  display: none;
}

.ant-menu-submenu-selected {
  font-weight: 500;
}

.ant-menu-submenu-active .ant-menu-title-content,
.ant-menu-submenu-selected .ant-menu-title-content{
  color: var(--primary-color);
}
.ant-menu-title-content {
  color: var(--primary-color);
}

.ant-menu-submenu > .ant-menu {
  border-radius: 0 0 10px 10px;
}

.ant-menu-item-group-list .ant-menu-item:hover {
  background: var(--grey-color);
}
