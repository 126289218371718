.switcher {
    width: 32px;
    height: 32px;
    background: #EDF0F4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    transition: background .2s ease-in-out;
}

.switcher:hover {
    cursor: pointer;
    background: #D5D8DB;
}
