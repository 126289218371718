.staking-alert {
    background: #fff;
    padding: 15px 15px;
    width: 410px;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.staking-alert-title {
    color: black;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
}
.staking-alert-content {
    font-size: 12px;
    display: block;
    margin-top: 5px;
}
.staking-alert-icon {
    width: 22px;
    height: 22px;
}

.dark .staking-alert {
    background: #201D3D;
}
