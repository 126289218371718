.bold {
  color: var(--dark-color);
}

.medium {
  color: var(--green-color);
}

.light {
  color: var(--red-color);
}
