.alert-modal {
  width: 420px !important;
}

.alert-modal .ant-modal-body {
  padding: 24px 24px 12px 24px !important;
}

.alert-modal .ant-modal-close-x {
  display: block !important;
}

.alert-modal .ant-modal-footer {
  padding: 16px 24px;
}

.alert-tabs .ant-tabs-tab-btn {
  font-size: 12px !important;
  border: 1px solid #d9d9d9;
  padding: 5px 0;
  border-radius: 2px;
}

.alert-tabs .ant-tabs-nav {
  display: none;
}

.alert-tabs .ant-tabs-ink-bar {
  display: none;
}

.alert-tabs.ant-tabs-small .ant-tabs-tab {
  padding: 0;
}

.alert-tabs .ant-tabs-nav::before {
  display: none;
}

.alert-radios {
  width: 100%;
  margin-bottom: 24px;
}

.alert-radios .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

.alert-modal .ant-modal-content{
  border-radius: 10px;
}

.alert-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.alert-modal .ant-modal-title {
  font-weight: 600;
}

.alert-modal .ant-modal-close-x {
  font-size: 14px;
}

.alert-notification-title {
  font-size: 15px;
  font-weight: 500;
  display: block;
  margin-bottom: 2px;
}

.alert-away-bold {
  color: var(--red-color);
  margin: 0 3px;
}

.alert-modal .ant-form-item-required::before {
  display: none !important;
}

.alert-modal-type-block {
  margin-top: 10px;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--grey-color);
}

.alert-modal .ant-form-item:last-of-type {
  margin-bottom: 10px;
}

.alert-modal-type {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.alert-modal-type.borderless {
  border-bottom: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.alert-modal-type .ant-col.ant-form-item-label {
  padding-bottom: 0;
}

.alert-modal-type .ant-form-item-control {
  flex: 0;
}

.alert-modal-telegram .ant-typography{
  display: block;
  margin-bottom: 20px;
}

.alert-modal-telegram p:last-of-type {
  margin-bottom: 8px;
}

.alert-modal-telegram p a {
  display: flex;
  align-items: center;
  gap: 5px;
}

.alert-qr {
  margin-bottom: 10px;
}

.alert-email-input {
  margin-top: 10px;
}

.dark .alert-modal .ant-modal-content, .dark .alert-modal .ant-modal-header{
  background: #201D3D;
  color: #fff;
}
.dark .alert-modal .ant-modal-header {
  border-bottom: none;
}
.dark .alert-modal .ant-modal-header .ant-modal-title{
  color: #fff;
}
.dark .alert-modal .ant-modal-footer {
  border-top: none;
}
.dark .alert-modal .ant-form-item-label label {
  color: #fff !important;
}
.dark .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #191633;
  border: 1px solid #0F0C28 !important;
}

.dark .alert-modal .ant-select-selection-item {
  color: #fff;
}
.dark .alert-modal input {
  background: #191633;
  border: 1px solid #0F0C28 !important;
}
