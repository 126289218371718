.statisticItem {
    color: var(--dark-grey-color);
    font-size: 12px;
}

.statisticItem:last-of-type {
    padding-right: 0;
    border-right: none;
}

.statisticItem-name {
    display: block;
}
.statisticItem-name:hover {
    cursor: pointer;
}

.statisticItem-name img {
    margin-left: 5px;
    margin-top: -1px;
}

.statisticItem-value {
    color: var(--dark-color);
    font-size: 16px;
    font-weight: 600;
}

.statisticItem-value.up {
    color: var(--green-color);
}

.statisticItem-value.down {
    color: var(--red-color);
}
