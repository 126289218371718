@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-color);
}

.ant-layout {
  background: var(--bg-color) !important;
  padding-bottom: 15px;
}

.ant-statistic-content {
  font-weight: 700;
}

.ant-table-thead tr .ant-table-cell {
  background: transparent;
  border-bottom: 0;
  color: rgba(0, 0, 0, 0.45);
}

.ant-table-tbody tr .ant-table-cell {
  border-bottom: 0;
}

.ant-space {
  width: 100%;
}

.ant-tabs-tab {
  width: 100%;
  background: none;
}

.ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-tab-btn {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-lg {
  font-size: 50px;
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-close-x {
  display: none !important;
}

.ant-table-cell {
  background: transparent !important;
}

.mobile-navigation {
  display: none;
}

.submenu-links {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  margin-top: 22px;
}
.submenu-links ul,
.submenu-links li {
  padding: 0 !important;
  margin: 0 !important;
}
.submenu-links li {
  margin-right: 35px !important;
}
.submenu-links a {
  color: rgba(0, 0, 0, 0.45) !important;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.ant-message-notice-content {
  max-width: 450px;
}

.ant-select-dropdown .ant-select-item {
 color: var(--primary-color);
}
