.repay strong{
    text-decoration: underline;
    cursor: pointer;
}

.new-repay {
    font-size: 13px;
}
.new-repay strong{
    background: rgba(0,0,0,.1);
    padding: 2px 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    color: #637599;
    position: relative;
    top: -1px;
    transition: .2s ease-in-out;
}
.new-repay strong:hover {
    background: rgba(0,0,0,.2);
}

.liquidation {
    color: var(--red-color);
}

.tab {
    padding: 20px;
}

.roundButtonInput {
    background: #EDF0F4;
    color: #637599;
    padding: 5px 10px;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    z-index: 10;
}
.roundButtonInput:hover, .roundButtonInput:focus, .roundButtonInput:active {
    background: var(--primary-color);
    color: #fff;
}

.roundButtonInput.left {
    left: 10px;
    right: auto;
}
.roundButtonInput.left:hover, .roundButtonInput.left:focus, .roundButtonInput.left:active {
    background: #EDF0F4;
}

.roundTokenAsset {
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.3);
    padding: 0 5px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-weight: 500;
    font-size: 12px;
    z-index: 10;
}

.inputPopup {
    /*padding-left: 45px;*/
}
.ant-input-number-input {
    line-height: normal;
}
.ant-input-number-input::placeholder {
    line-height: normal;
}
