.health {
    flex: 1;
}

.info {
    text-align: right;
    display: block;
    color: var(--dark-grey-color);
    font-size: 12px;
    margin-bottom: 0;
}
